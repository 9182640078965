"use strict";


const baseURL = "https://chatbook-78786-ruby.b78786.dev.centralindia.az.svc.builder.ai";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
